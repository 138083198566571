'use client'

import { ChangeEvent, useState } from 'react'
import { Button, Text, Cell, Spacer, Icon } from '@vinted/web-ui'
import { X16, CheckCircleFilled32 } from '@vinted/monochrome-icons'

import { changeExistingEmail } from 'data/api'
import { isEmailValid } from '_libs/validators'

import { ClickableElement } from 'constants/tracking/clickable-elements'
import { clickEvent } from '_libs/common/event-tracker/events'

import Banner from 'components/Banner'
import { FormattedMessage } from 'components/@support'
import { DeprecatedInputText } from 'components/Input'
import useSession from 'hooks/useSession'
import useTracking from 'hooks/useTracking'

export enum BannerStatus {
  Idle = 'idle',
  Pending = 'pending',
  Subscribed = 'subscribed',
  Finished = 'finished',
}

const EmailConfirmationBanner = () => {
  const { user } = useSession()

  const [email, setEmail] = useState<string>(user?.email || '')
  const [isValid, setIsValid] = useState<boolean>(!!user?.email)
  const [status, setStatus] = useState<BannerStatus>(BannerStatus.Idle)

  const { track } = useTracking()

  const handleClick = async () => {
    if (!user) return

    setStatus(BannerStatus.Pending)

    const response = await changeExistingEmail({ userId: user.id, email })

    if ('errors' in response) return

    setStatus(BannerStatus.Subscribed)

    const event = clickEvent({ target: ClickableElement.ChangeEmail })
    track(event)
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
    setIsValid(isEmailValid(event.target.value))
  }

  const closeNotification = () => setStatus(BannerStatus.Finished)

  const renderSuccessNotification = () => {
    return (
      <Cell
        theme="success"
        suffix={
          <Button
            styling={Button.Styling.Flat}
            icon={<Icon name={X16} color={Icon.Color.GreyscaleLevel7} />}
            onClick={closeNotification}
          />
        }
      >
        <div className="u-flexbox u-align-items-center">
          <Icon name={CheckCircleFilled32} color={Icon.Color.GreyscaleLevel7} />
          <Spacer orientation={Spacer.Orientation.Vertical} />
          <Text
            as="h2"
            text={<FormattedMessage id="email_confirmation.banner.success" values={{ email }} />}
            type={Text.Type.Title}
            theme="inverse"
          />
        </div>
      </Cell>
    )
  }

  const renderBanner = () => {
    const disabledAction = status === BannerStatus.Pending || !isValid
    const validationMessage = (
      <Text
        as="span"
        text={<FormattedMessage id="email_confirmation.banner.invalid" />}
        theme="warning"
      />
    )

    return (
      <Banner
        title={
          <Text
            as="h2"
            text={<FormattedMessage id="email_confirmation.banner.title" />}
            type={Text.Type.Title}
          />
        }
        body={
          <DeprecatedInputText
            name="email"
            type="text"
            styling="narrow"
            value={email}
            placeholder={email}
            onChange={handleInputChange}
            validation={isValid ? null : validationMessage}
          />
        }
        actions={[
          <Button
            text={<FormattedMessage id="email_confirmation.banner.button" />}
            styling={Button.Styling.Filled}
            onClick={handleClick}
            disabled={disabledAction}
          />,
        ]}
      />
    )
  }

  if (status === BannerStatus.Finished) return null

  const showBanner = status === BannerStatus.Idle || status === BannerStatus.Pending

  return (
    <>
      {showBanner && renderBanner()}
      {status === BannerStatus.Subscribed && renderSuccessNotification()}
    </>
  )
}

export default EmailConfirmationBanner
