'use client'

import { useEffect } from 'react'

import PersonalizationBanner from 'components/PersonalizationBanner'
import NetPromoterScore from 'components/NetPromoterScore'
import EmailConfirmationBanner from 'components/EmailConfirmationBanner'

import PortalDraftItemReminderBanner from 'components/PortalDraftItemReminderBanner'
import PortalMergeAnnouncementModal from 'components/PortalMergeAnnouncementModal'
import MergeWelcomeScreenModal from 'components/MergeWelcomeScreenModal'
import OnboardingModal from 'components/OnboardingModal'
import CatalogRulesBanner from 'components/CatalogRulesBanner'
import TermsAndConditionsBanner from 'components/TermsAndConditionsBanner'
import BeyondFashionBanner from 'components/BeyondFashionBanner'
import { TaxpayerBannerInHome } from 'components/TaxpayerBanner'

import useBanners from 'hooks/useBanners'

const TopBanners = () => {
  const { fetchBanners, banners } = useBanners()

  useEffect(() => {
    fetchBanners()
  }, [fetchBanners])

  function renderBanner<T extends keyof typeof banners>(
    name: T,
    BannerComponent: React.ComponentType<{ banner: NonNullable<(typeof banners)[T]> }>,
  ) {
    const banner = banners[name]

    if (!banner) return null

    return <BannerComponent banner={banner} />
  }

  function renderOnboardingModal() {
    const multiVariantsBanner = banners.multiVariantsOnboardingModal
    const banner = banners.onboardingModal

    if (!banner) return null

    return <OnboardingModal banner={banner} multiVariantsBanner={multiVariantsBanner} />
  }

  return (
    <>
      <div className="homepage-top-banners">
        {renderBanner('taxpayerBanner', TaxpayerBannerInHome)}
        {renderBanner('feedPersonalizationBanner', PersonalizationBanner)}
        {renderBanner('portalMergeDraftItemsReminder', PortalDraftItemReminderBanner)}
        {renderBanner('nps', NetPromoterScore)}
        {renderBanner('emailConfirmation', EmailConfirmationBanner)}
        {renderBanner('termsAndConditions', TermsAndConditionsBanner)}
        {renderBanner('catalogRules', CatalogRulesBanner)}
        {renderBanner('beyondFashion', BeyondFashionBanner)}
      </div>
      {/* Modals below */}
      {renderBanner('portalMergeSourceAnnouncement', PortalMergeAnnouncementModal)}
      {renderBanner('portalMergeWelcomeScreen', MergeWelcomeScreenModal)}
      {renderOnboardingModal()}
    </>
  )
}

export default TopBanners
