'use client'

import { useRef } from 'react'
import { InView } from 'react-intersection-observer'

import useTracking from 'hooks/useTracking'
import {
  clickEvent,
  clickListItemEvent,
  favouriteItemEvent,
  impressionEvent,
} from '_libs/common/event-tracker/events'
import { ProductItemModel } from 'types/models'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { ContentSource } from 'constants/tracking/content-sources'
import { ProductItem } from 'components/ProductItem'
import { ListItemContentType } from 'constants/tracking/content-types'

type CardItemsProps = {
  items: Array<ProductItemModel>
  homepageSessionId: string
}

const CardItems = ({ items, homepageSessionId }: CardItemsProps) => {
  const { track } = useTracking()
  const seenItemIds = useRef<Array<number>>([])

  function handleFavouriteToggle({
    itemId,
    isFollowEvent,
    itemContentSource,
  }: {
    itemId: number
    isFollowEvent: boolean
    itemContentSource?: ContentSource | null
  }) {
    track(clickEvent({ target: ClickableElement.Favourite }))
    track(
      favouriteItemEvent({
        itemId,
        isFollowEvent,
        contentSource: itemContentSource,
        homepageSessionId,
      }),
    )
  }

  const handleItemVisibility = (item: ProductItemModel, index: number) => (inView: boolean) => {
    const { id, user, contentSource } = item

    if (!inView) return
    if (seenItemIds.current.includes(id)) return

    seenItemIds.current.push(id)

    track(
      impressionEvent({
        id,
        position: index + 1,
        contentType: ListItemContentType.Item,
        contentSource: contentSource as ContentSource,
        itemOwnerId: user.id,
        homepageSessionId,
      }),
    )
  }

  const handleItemClick = (item: ProductItemModel, index: number) => () => {
    track(
      clickListItemEvent({
        id: item.id,
        contentType: ListItemContentType.Item,
        contentSource: item.contentSource as ContentSource,
        position: index + 1,
        homepageSessionId,
      }),
    )
  }

  const renderItems = () => {
    const productItems = items.map((item, index) => (
      <InView
        onChange={handleItemVisibility(item, index)}
        className="suggested-product"
        key={`item-${item.id}`}
      >
        <ProductItem
          item={item}
          showOwner={false}
          onFavouriteToggle={handleFavouriteToggle}
          showStatus
          isBumpTextHidden
          informationCellCssClasses="item-box-information-cell"
          onClick={handleItemClick(item, index)}
        />
      </InView>
    ))

    return productItems
  }

  return <div className="card-items-container">{renderItems()}</div>
}

export default CardItems
