import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'

import { transformHomepageSchemaMarkup } from '../../next/libs/seo/schema-markup/transformers'
import useAsset from './useAsset'
import useLocation from './useLocation'
import useTranslate from './useTranslate'

const useHomeSchemaMarkup = (pageTitle: string) => {
  const { baseUrl } = useLocation()
  const translate = useTranslate()
  const isSchemaMarkupEnabled = useFeatureSwitch('schema_markup_homepage')
  const asset = useAsset('/assets/web-logo/default')

  if (!isSchemaMarkupEnabled) return undefined

  return transformHomepageSchemaMarkup({
    meta_title: pageTitle,
    meta_description: translate('main.page_description'),
    baseUrl,
    logo: asset('logo.svg'),
  })
}

export default useHomeSchemaMarkup
