import { useState, useCallback } from 'react'
import { isEmpty } from 'lodash'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { ClosetModel } from 'types/models'
import { getClosetPromotions } from 'data/api'
import { transformClosets } from 'data/transformers/closet'
import { getFirstListedBreakpoint } from 'components/Breakpoint'
import { Screen } from 'constants/tracking/screens'
import useBreakpoint from 'hooks/useBreakpoint'
import useAbTest from 'hooks/useAbTest'

import { feedInsertPositions } from '../pages/Home/Feed/constants'

type Props = {
  isEnabled: boolean
}

const useFetchClosets = ({ isEnabled }: Props) => {
  const breakpoints = useBreakpoint()
  const isVasHarmEnabled =
    useAbTest({ abTestName: 'vas_harm_v2', shouldTrackExpose: true })?.variant === 'on'
  const isClosetPromotionKillswitchEnabled = useFeatureSwitch('killswitch_closet_promotion_web')

  const [closets, setClosets] = useState<Array<ClosetModel>>([])

  const fetchClosetPromo = useCallback(
    async (itemCount: number) => {
      const tabletsUpBreakpoint =
        getFirstListedBreakpoint(breakpoints.active, ['wide', 'desktops']) || 'tablets'

      const shouldFetch =
        isEnabled &&
        !isVasHarmEnabled &&
        !isClosetPromotionKillswitchEnabled &&
        itemCount >= feedInsertPositions.first[tabletsUpBreakpoint]

      if (!shouldFetch) return

      const userIds = closets.map(closet => closet.user.id)

      const breakpoint = getFirstListedBreakpoint(breakpoints.active) || 'phones'

      const breakpointToClosetPromoCount = {
        wide: 3,
        desktops: 4,
        tablets: 5,
        phones: 5,
      }

      const closetPromoCount = breakpointToClosetPromoCount[breakpoint]

      const response = await getClosetPromotions({
        perPage: closetPromoCount,
        excludedUserIds: userIds,
        screenName: Screen.Homepage,
      })

      if ('errors' in response) return

      const transformedClosets = transformClosets(response.promoted_closets)

      if (isEmpty(transformedClosets)) return

      const isFirstFetch = !closets.length && transformedClosets.length > 0

      if (isFirstFetch && transformedClosets[0]) {
        transformedClosets[0].showBanner = true
      }

      setClosets(prevArray => [...prevArray, ...transformedClosets])
    },
    [breakpoints.active, isEnabled, isVasHarmEnabled, isClosetPromotionKillswitchEnabled, closets],
  )

  return { closets, fetchClosetPromo }
}

export default useFetchClosets
