'use client'

import { Button, Cell, Spacer, Text } from '@vinted/web-ui'
import { useRef } from 'react'
import { InView } from 'react-intersection-observer'

import HorizontallyScrollableItems from 'components/HorizontallyScrollableItems'
import {
  userClickHomepageBlockCta,
  userViewHomepageBlock,
  userViewHomepageBlockCta,
} from '_libs/common/event-tracker/events'
import { ItemBlockCtaType } from 'constants/home'
import { HomepageItemBlockCtaModel } from 'types/models/homepage-blocks'
import { GenericPromoBoxModel, ProductItemModel } from 'types/models'
import useTracking from 'hooks/useTracking'
import useBreakpoint from 'hooks/useBreakpoint'
import ErrorBoundary from 'components/ErrorBoundary'

import BlockTitle from '../../common/BlockTitle'
import { logHomeError } from '../../utils/observability'

type Props = {
  id?: string
  title: string
  subtitle?: string | null
  name: string
  items: Array<ProductItemModel>
  promoBox: GenericPromoBoxModel | null
  homepageSessionId: string
  position: number
  headerCta?: HomepageItemBlockCtaModel | null
  itemCta?: HomepageItemBlockCtaModel | null
}

const ItemsBlock = ({
  title,
  subtitle,
  name,
  items,
  promoBox,
  homepageSessionId,
  position,
  headerCta,
  itemCta,
  id,
}: Props) => {
  const { track } = useTracking()
  const breakpoints = useBreakpoint()

  const isItemsBlockSeen = useRef(false)
  const seenCtas = useRef<Array<ItemBlockCtaType>>([])

  const handleCtaClick = (type: ItemBlockCtaType) => () => {
    track(
      userClickHomepageBlockCta({
        type,
        blockName: name,
        blockPosition: position,
        homepageSessionId,
        id,
      }),
    )
  }

  const handleCtaView = (type: ItemBlockCtaType) => () => {
    if (seenCtas.current.includes(type)) return

    seenCtas.current.push(type)
    track(
      userViewHomepageBlockCta({
        type,
        blockName: name,
        blockPosition: position,
        homepageSessionId,
        id,
      }),
    )
  }

  const handleHeaderCtaView = (inView: boolean) =>
    inView && handleCtaView(ItemBlockCtaType.Header)()

  const renderHeader = () => (
    <>
      <Cell
        styling={Cell.Styling.Tight}
        title={
          <div className="homepage-layouts-text-truncation">
            <BlockTitle title={title} width="Parent" />
          </div>
        }
        body={
          subtitle ? (
            <div className="homepage-layouts-text-truncation">
              <Text type={Text.Type.Subtitle} width={Text.Width.Parent} text={subtitle} as="p" />
            </div>
          ) : null
        }
        testId={`${name}-header`}
        suffix={
          headerCta && (
            <InView onChange={handleHeaderCtaView}>
              <Button
                url={headerCta.url}
                onClick={handleCtaClick(ItemBlockCtaType.Header)}
                styling={Button.Styling.Flat}
                text={<Text as="span" theme="primary" text={headerCta.title} />}
                aria={{
                  'aria-label': headerCta.accessibilityLabel || '',
                }}
                testId={`${name}-header-cta`}
              />
            </InView>
          )
        }
        fullWidthTitle
      />
      {breakpoints.phones ? (
        <Spacer size={Spacer.Size.Regular} />
      ) : (
        <Spacer size={Spacer.Size.Large} />
      )}
    </>
  )

  const renderCta = () => {
    if (!itemCta) return null

    return (
      <HorizontallyScrollableItems.CtaItem
        url={itemCta.url}
        onClick={handleCtaClick(ItemBlockCtaType.Item)}
        content={itemCta.title}
        testId={`${name}-item-cta`}
        onEnter={handleCtaView(ItemBlockCtaType.Item)}
      />
    )
  }

  const handleBlockView = (inView: boolean) => {
    if (!inView) return
    if (isItemsBlockSeen.current) return

    isItemsBlockSeen.current = true

    track(
      userViewHomepageBlock({ blockName: name, blockPosition: position, homepageSessionId, id }),
    )
  }

  return (
    <InView onChange={handleBlockView}>
      <HorizontallyScrollableItems
        items={items}
        cta={renderCta()}
        promoBox={promoBox}
        header={renderHeader()}
        homepageSessionId={homepageSessionId}
        itemTestId={name}
        preventLog
        testId={`${name}-block`}
        itemsFullWidthAlignment
      />
    </InView>
  )
}

const ItemsBlockErrorBoundary = (props: Props) => (
  <ErrorBoundary
    FallbackComponent={ErrorBoundary.ComponentError}
    preventLog
    onError={err => logHomeError(err, props.name)}
  >
    <ItemsBlock {...props} />
  </ErrorBoundary>
)

export default ItemsBlockErrorBoundary
