import { useState, useCallback, useRef } from 'react'
import { isEmpty } from 'lodash'

import { FeedEventDto } from 'types/dtos'
import { getFeedEvents } from 'data/api'
import { endOfListEvent } from '_libs/common/event-tracker/events'

import useLatestCallback from 'hooks/useLatestCallback'
import useTracking from 'hooks/useTracking'
import useTabs from 'pages/Home/hooks/useTabs'
import { useHomeContext } from 'pages/Home/HomeProvider'
import { ResponseError } from 'types/api/response'
import { EMPTY_RESPONSE_ERROR } from 'pages/Home/utils/api'

type Props = {
  onEmpty: (error: ResponseError) => void
}

const useFetchFeedItems = ({ onEmpty }: Props) => {
  const { track } = useTracking()
  const onEmptyMemoized = useLatestCallback(onEmpty)
  const { homepageSessionId } = useHomeContext()
  const { currentTab } = useTabs()

  const [events, setEvents] = useState<Array<FeedEventDto>>([])
  const [maxScore, setMaxScore] = useState<number | null>(null)
  const [endReached, setEndReached] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false)

  const pagesFetched = useRef(0)
  const isFirstPageFetched = pagesFetched.current > 0

  const fetchFeedEvents = useCallback(async () => {
    if (isLoading || endReached) return null

    setIsLoading(true)

    const isFirstFetch = !events.length
    const props = isFirstFetch ? {} : { maxScore, itemCount: events.length }
    const response = await getFeedEvents({ ...props, tabName: currentTab.name, homepageSessionId })

    if ('errors' in response) {
      if (isFirstFetch) onEmptyMemoized(response)
      setEndReached(true)
      setIsLoading(false)

      return null
    }

    const {
      feed_events: newEvents,
      show_load_more_button: loadMore,
      max_score: newMaxScore,
    } = response

    if (isEmpty(newEvents)) {
      if (isFirstFetch) onEmptyMemoized(EMPTY_RESPONSE_ERROR)
      setEndReached(true)
      setIsLoading(false)

      track(endOfListEvent({ itemCount: events.length }))

      return null
    }

    setMaxScore(newMaxScore)
    setEvents(prevArray => [...prevArray, ...newEvents])
    setIsLoading(false)
    setShowLoadMoreButton(loadMore)

    pagesFetched.current += 1

    return events.length + newEvents.length
  }, [
    events.length,
    maxScore,
    track,
    isLoading,
    currentTab,
    onEmptyMemoized,
    endReached,
    homepageSessionId,
  ])

  return {
    events,
    fetchFeedEvents,
    endReached,
    isLoading,
    showLoadMoreButton,
    isFirstPageFetched,
  }
}

export default useFetchFeedItems
