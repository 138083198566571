'use client'

import { MouseEvent } from 'react'
import { Image } from '@vinted/web-ui'
import { useInView } from 'react-intersection-observer'

import { getTestId } from '_libs/utils/testId'
import AspectRatio from 'components/AspectRatio'

type Props = {
  image?: string
  color?: string
  url: string | null | undefined
  impressionUrl?: string | null
  testId?: string
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void
  onEnter?: () => void
}

// TODO: replace `AspectRatio` with the `aspect-ratio` CSS property once we drop iOS 14 support
const PromoBox = ({ image, color, url, impressionUrl, testId, onClick, onEnter }: Props) => {
  const { ref } = useInView({
    onChange: inView => {
      if (!inView) return
      onEnter?.()
    },
  })

  return (
    <a
      href={url || undefined}
      className="u-block u-fill-width u-fill-height"
      data-testid={getTestId(testId, 'promo-box')}
      onClick={onClick}
      ref={ref}
    >
      <AspectRatio ratio="1:2">
        <Image src={image} color={color} scaling={Image.Scaling.Cover} />
      </AspectRatio>
      {impressionUrl && (
        <img
          height="1"
          width="1"
          alt=""
          style={{ display: 'none', border: 0 }}
          src={impressionUrl}
        />
      )}
    </a>
  )
}

export default PromoBox
