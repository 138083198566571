import { useCallback, useRef } from 'react'

import { FeedEventDto, FeedItemDto } from 'types/dtos'
import { ListerActivationBannerModel, ClosetModel } from 'types/models'
import { ItemVisibleCallbackArgs } from 'types/components'

import { ContentSource } from 'constants/tracking/content-sources'
import { ListItemContentType } from 'constants/tracking/content-types'

import { impressionEvent } from '_libs/common/event-tracker/events'
import { TrackingEvent } from '_libs/common/event-tracker/types'

import useTracking from 'hooks/useTracking'

const useItemVisibilityTracking = (homepageSessionId?: string) => {
  const { track } = useTracking()

  const seenAds = useRef<Array<string | number>>([])
  const seenClosets = useRef<Array<number>>([])
  const seenListerActivationBanners = useRef<Array<string | number>>([])
  const seenItems = useRef<Array<number>>([])

  const handleItemVisibility = useCallback(
    (args: ItemVisibleCallbackArgs<object>) => {
      const { item, index: itemIndex } = args
      const { data, id } = item
      const trackingEvents: Array<TrackingEvent> = []

      switch (item.type) {
        case 'generic_promo_box':
          break

        case 'ad': {
          if (seenAds.current.includes(id)) return

          track(
            impressionEvent({
              id,
              position: itemIndex + 1,
              contentType: ListItemContentType.Ad,
              contentSource: ContentSource.AdFeed,
            }),
          )

          seenAds.current.push(id)

          break
        }

        case 'closet_promotion': {
          const closetPromotion = data as ClosetModel

          if (seenClosets.current.includes(closetPromotion.user.id)) return

          trackingEvents.push(
            impressionEvent({
              id: closetPromotion.user.id,
              position: itemIndex + 1,
              contentType: ListItemContentType.PromotedCloset,
              contentSource: ContentSource.PromotedClosets,
              homepageSessionId,
            }),
          )

          seenClosets.current.push(closetPromotion.user.id)

          break
        }

        case 'lister_activation_banner': {
          const banner = data as ListerActivationBannerModel

          if (seenListerActivationBanners.current.includes(id)) return

          trackingEvents.push(
            impressionEvent({
              id: banner.catalogId,
              position: itemIndex + 1,
              contentType: ListItemContentType.ListerActivationBanner,
              contentSource: ContentSource.ListerActivationBanner,
            }),
          )

          seenListerActivationBanners.current.push(id)

          break
        }

        default: {
          const feedEvent = data as FeedEventDto

          if (seenItems.current.includes(feedEvent.entity.id)) return

          trackingEvents.push(
            impressionEvent({
              id: feedEvent.entity.id,
              position: itemIndex + 1,
              contentType: ListItemContentType.Item,
              contentSource: feedEvent.content_source,
              itemOwnerId: item.type === 'item' ? (feedEvent.entity as FeedItemDto).user.id : null,
              homepageSessionId: item.type === 'item' ? homepageSessionId : undefined,
            }),
          )

          seenItems.current.push(feedEvent.entity.id)

          break
        }
      }

      trackingEvents.forEach(event => track(event))
    },
    [homepageSessionId, track],
  )

  return {
    handleItemVisibility,
  }
}

export default useItemVisibilityTracking
